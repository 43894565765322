
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import StatusSelect from "@/components/StatusSelect.vue"
import { VueConstructor } from "vue/types/umd"
import DateFormatMixin from "@/mixins/DateFormatMixin.vue"
import DatePicker from "@/components/DatePicker.vue"

interface Profile {
    [key: string]: string
}

interface SelectElt {
  value: string,
  text: string
}

interface Body {
    [key: string]: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
    name: "WorkerFormUpdateProfile",
    mixins: [
        DateFormatMixin,
    ],
    components: { StatusSelect, DatePicker },
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        dataReference: {} as Profile,
        profile: {
            id: "",
            space_name: "",
            profile_picture: "",
            sex: "",
            first_name: "",
            last_name: "",
            status: "",
            state: "",
            inscription_origin: "",
            email: "",
            agency_id: "",
            mobile_phone_number: "",
            phone_number: "",
            postal_address: "",
            postal_code: "",
            postal_city: "",
            postal_country: "",
            nationality: "",
            birth_date: "",
            birth_city: "",
            birth_department: "",
            birth_country: "",
            nir: "",
            family_situation: "",
            child_in_charge: "",
            wage_category: "",
            payement_category: "",
            bank_domiciliation: "",
            bank_beneficiary: "",
            bank_code: "",
            bank_wicket_code: "",
            bank_account_number: "",
            bank_key: "",
            created_at: "",
            updated_at: "",
            matricule: "",
            external_ref: "",
            transportation_means: ""
        } as Profile,
        stateList: [] as Array<SelectElt>,
        civilityList: [] as Array<SelectElt>,
        transportList: [] as Array<SelectElt>,
        wageCategoryList: [] as Array<SelectElt>,
        payementCategoryList: [] as Array<SelectElt>,
        familySituationList: [] as Array<SelectElt>,
        comparison: {} as Body,
        emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxBirthDate: "",
    }),
    created: function() {
        let date = new Date()
        date.setFullYear(date.getFullYear() - 18)
        this.maxBirthDate = date.toISOString().substr(0, 10)
    },
    computed: {
        emailRule() {
            return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
        },
        requiredRule() {
            return (v: string) => (!!v) || this.$vuetify.lang.t('$vuetify.required_field')
        },
        emailOrPhoneRequired() {
            return (v: string) => (!!this.profile.email  || !!this.profile.mobile_phone_number) || this.$vuetify.lang.t('$vuetify.phone_or_email_required')
        },
        mobilePhoneNumberRule() {
            return (v: string) => ((v.length > 1 && v.length < 15) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
        },
        nameLengthRule() {
            return (v: string) => ((v.length > 1 && v.length < 100) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
        },
        stringLengthRule() {
            return (v: string) => ((v.length > 1 && v.length < 254) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
        }
    },
    methods: {
        updateBirthDate(birth_date: string): void{
            this.profile.birth_date = birth_date
        },
        async getWorker() {
            await axios
                .get(`/v1/workers/${this.id}?fields=${Object.keys(this.profile).toString()}`)
                .then((response: AxiosResponse) => {
                    for (const property in response.data) {
                        this.profile[property as keyof Profile] = response.data[property] ?? ""
                        this.dataReference[property as keyof Profile] = response.data[property] ?? ""
                    }
                    this.uppercase()
                })
                .catch((error: AxiosError) => {
                    EventBus.$emit('snackbar',{axiosError: error});
                })
            this.comparison = {}
        },
        getSpace() {
            axios
                .get(`/v1/spaces/${this.profile.agency_id}`)
                .then((response: AxiosResponse) => {
                    this.profile.space_name = response.data[0].name
                })
                .catch((error: AxiosError) => {
                    EventBus.$emit('snackbar',{axiosError: error});
                })
            this.comparison = {}
        },
        uppercase() {
            this.profile.last_name = this.profile.last_name.toUpperCase();
        },
        updateData() {
            if (Object.keys(this.comparison).length > 0 && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
                axios
                    .put(`/v1/workers/${this.id}`, this.comparison)
                    .then((response: AxiosResponse) => {
                        EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.update_success')})
                        this.getWorker()
                    })
                    .catch((e: AxiosError) => {
                        EventBus.$emit('snackbar',{axiosError: e})
                    })
            }
        },
        cancel() {
            if (Object.keys(this.comparison).length > 0 ) {
                this.getWorker()
            }
        },
        updateDocument(event: any) {
            let formData = new FormData()
            formData.append("files[]", event.target.files[0])

            axios
                .post("/uploads", formData)
                .then((response) => {
                    this.profile.profile_picture = response.data.success[0]
                    
                    this.comparison["profile_picture"] = response.data.success[0]
                    this.updateData()
                })
        }
    },
    async mounted() {
        await this.getWorker()
        this.getSpace()

        this.stateList = [
            {value: "web", text: this.$vuetify.lang.t("$vuetify.worker_states.web").toUpperCase()},
            {value: "candidate", text: this.$vuetify.lang.t("$vuetify.worker_states.candidate").toUpperCase()},
            {value: "employee", text: this.$vuetify.lang.t("$vuetify.worker_states.employee").toUpperCase()},
            {value: "applicant", text: this.$vuetify.lang.t("$vuetify.worker_states.applicant").toUpperCase()},
            {value: "pre-registered", text: this.$vuetify.lang.t("$vuetify.worker_states.pre_registered").toUpperCase()},
            {value: "supplements OK", text: this.$vuetify.lang.t("$vuetify.worker_states.supplement_ok").toUpperCase()},
        ]
        this.transportList = [
            {value: "car", text: this.$vuetify.lang.t("$vuetify.car")},
            {value: "motorbike", text: this.$vuetify.lang.t("$vuetify.motorbike")},
            {value: "bike_scooter", text: this.$vuetify.lang.t("$vuetify.bike_scooter")},
            {value: "mass_transit", text: this.$vuetify.lang.t("$vuetify.mass_transit")},
            {value: "moped", text: this.$vuetify.lang.t("$vuetify.moped")},
            {value: "walk", text: this.$vuetify.lang.t("$vuetify.walk")},
            {value: "carpool", text: this.$vuetify.lang.t("$vuetify.carpool")}
        ]
        this.wageCategoryList = [
            {value: "executive", text: this.$vuetify.lang.t("$vuetify.executive")},
            {value: "short_haul_driver", text: this.$vuetify.lang.t("$vuetify.short_haul_drivers")},
            {value: "teacher_contract_45", text: this.$vuetify.lang.t("$vuetify.teacher_contract_45")},
            {value: "employee", text: this.$vuetify.lang.t("$vuetify.employee")},
            {value: "EEC_employee", text: this.$vuetify.lang.t("$vuetify.EEC_employee")},
            {value: "long_haul_driver", text: this.$vuetify.lang.t("$vuetify.long_haul_driver")},
            {value: "executive_pension", text: this.$vuetify.lang.t("$vuetify.executive_pension")},
            {value: "executive_pension_65", text: this.$vuetify.lang.t("$vuetify.executive_pension_65")},
            {value: "employee_pension", text: this.$vuetify.lang.t("$vuetify.employee_pension")},
            {value: "employee_pension_65", text: this.$vuetify.lang.t("$vuetify.employee_pension_65")}
        ]
        this.payementCategoryList = [
            {value: "bank_transfer", text: this.$vuetify.lang.t("$vuetify.bank_transfer")},
            {value: "bank_cheque", text: this.$vuetify.lang.t("$vuetify.bank_cheque")}
        ]
        this.familySituationList = [
            {value: "single", text: this.$vuetify.lang.t("$vuetify.single")},
            {value: "in_couple", text: this.$vuetify.lang.t("$vuetify.in_couple")},
            {value: "married", text: this.$vuetify.lang.t("$vuetify.married")},
            {value: "divorced", text: this.$vuetify.lang.t("$vuetify.divorced")},
            {value: "widowed", text: this.$vuetify.lang.t("$vuetify.widowed")},
            {value: "civil_union", text: this.$vuetify.lang.t("$vuetify.civil_union")},
            {value: "separated", text: this.$vuetify.lang.t("$vuetify.separated")}
        ]
        this.civilityList = [
            {value: "M", text: this.$vuetify.lang.t("$vuetify.mister").toUpperCase()},
            {value: "F", text: this.$vuetify.lang.t("$vuetify.misses").toUpperCase()}
        ]
    },
    watch: {
        async $route () {
            await this.getWorker()
            this.getSpace()
        },
        profile: {
            handler(){
                for(const property in this.dataReference) {
                    if(this.profile[property] !== this.dataReference[property]) {
                        this.comparison[property] = this.profile[property];
                    } else {
                        delete this.comparison[property]
                    }
                }
            },
            deep: true
        }
    },
})

import { render, staticRenderFns } from "./WorkerTabsComponent.vue?vue&type=template&id=3b5e2a69&scoped=true"
import script from "./WorkerTabsComponent.vue?vue&type=script&lang=ts"
export * from "./WorkerTabsComponent.vue?vue&type=script&lang=ts"
import style0 from "./WorkerTabsComponent.vue?vue&type=style&index=0&id=3b5e2a69&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5e2a69",
  null
  
)

export default component.exports
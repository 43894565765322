
import Vue from "vue"

interface Status {
  value: string,
  text: string
}

export default Vue.extend({
    name: "StatusSelect",
    data: () => ({
        statusList: [] as Array<Status>
    }),
    props: {
        value: { // used to make v-model works
            type: String,
            default: ""
        }
    },
    computed: {
        statusColor: function() {
            if (this.inputValue === "available") {
                return "#49b157"
            } else if (this.inputValue === "unavailable") {
                return "#ff4f54"
            } else if (this.inputValue === "contract") {
                return "#2a93ef"
            } else if (this.inputValue === "blocked") {
                return "#000000"
            } else if (this.inputValue === "pending") {
                return "#ee81ee"
            } else {
                return "#808080"
            }
        },
        inputValue: { // bind value prop to our v-model on v-dialog
            get(): string {
                return this.value
            },
            set(val: string) {
                this.$emit('input', val)
            }
        }
    },
    mounted() {
        this.statusList = [
            {value: "available", text: this.$vuetify.lang.t("$vuetify.order_worker_status.available").toUpperCase()},
            {value: "unavailable", text: this.$vuetify.lang.t("$vuetify.order_worker_status.unavailable").toUpperCase()},
            {value: "contract", text: this.$vuetify.lang.t("$vuetify.order_worker_status.contract").toUpperCase()},
            {value: "unknown", text: this.$vuetify.lang.t("$vuetify.order_worker_status.unknown").toUpperCase()},
            {value: "blocked", text: this.$vuetify.lang.t("$vuetify.order_worker_status.blocked").toUpperCase()},
            {value: "pending", text: this.$vuetify.lang.t("$vuetify.order_worker_status.pending").toUpperCase()}
        ]
    }
})

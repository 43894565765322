
import Vue from "vue"
import { VueConstructor } from "vue/types/umd"
import WorkerFormInterview from "./WorkerFormInterview.vue"
import WorkerFormUpdateProfile from "@/components/worker/WorkerFormUpdateProfile.vue"

export default (Vue as VueConstructor<Vue & InstanceType<typeof WorkerFormInterview> & InstanceType<typeof WorkerFormUpdateProfile>>).extend({
    name: "WorkerTabsComponent",
    components: {WorkerFormInterview, WorkerFormUpdateProfile},
    data: () => ({
        tab: 0
    })
})
